import { getUserById } from "../../../utils/data";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";

export default function NotificationItem({ PersonId, status, text, createdAt, id, notificationToken, isDelivered }) {

    return <tr>
        <td className={`notification-status notification-${status}`}>{status}</td>
        <td className="notification-name">{getUserById(PersonId)?.name}</td>
        <td className="notification-text">{text}</td>
        <td className="notification-time">{moment(createdAt).format('MM-DD/HH:mm')}</td>
        <td className="notification-id">{id}</td>
        <td className="notification-token">{notificationToken}</td>
    </tr>


    // <div className={`notification__item notification__item--${status}`}>
    //     <span className="text">{text}</span>
    //     <span className="name">{getUserById(PersonId)?.name}</span>
    //     <span className="time">{moment(createdAt).format('MM-DD/HH:mm')}</span>
    //     <span className="id">{id}</span>
    //     <span className="status">{status}</span>
    //     <span className="token">{notificationToken}</span>
    //     {isDelivered ? <FontAwesomeIcon className='delivered ' icon={faMobile} /> : <div className="delivered"></div>}
    // </div>
}
