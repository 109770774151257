
import {
    Link
} from "react-router-dom";

export default function Header() {
    return (
        <div className="header">
            <nav className="nav-bar">
                <ul>
                    <Link to="/notification"><li>Архів</li></Link>
                    <Link to="/users"><li>Користувачі</li></Link>
                    <Link to="/push"><li>Відправка повідомлень</li></Link>
                </ul>
            </nav>
        </div>
    )
}