// const url = "http://192.168.1.31:3000"
// const url = "http://85.238.113.34:3000"

const url = ''
let serverKey = '';

const myFetch = (path, body, method = 'GET') => fetch(url ? url + "/" + path : path, {
    method,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'key': serverKey
    },
    body: JSON.stringify(body)
}).then(res => res.json());


const getUsers = () => myFetch("users")
const getOnlineUsers = () => myFetch("onlineUsers")
const getNotification = (data) => myFetch("getNotification", data, "POST")
const updateUser = (user) => myFetch("updateUser", user, "POST")
const deleteUser = (id) => myFetch("deleteUser", { id }, "POST")
const sendPush = (text) => myFetch("sendPushForAll", { text }, "POST")

const login = (userName, password) => {
    const loginData = fetch(url ? url + "/login" : "login"
        , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ userName, password })
        }
    ).then(res => res.json())
    loginData.then(({ key }) => /* console.log("server key - ", */ serverKey = key/* ) */)

    return loginData
}

export { getUsers, updateUser, getNotification, login, deleteUser, sendPush, getOnlineUsers }
