import { useCallback, useState } from "react"
import { updateUser } from "../../../utils/server"
import NameSetter from "./NameSetter"
import Tokens from "./Tokens"
import UserSelect from "./UserSelect"

function UserItem(props) {
    const id = props.user.id
    const name = props.user.name
    const status = props.user.status
    const manager = props.user.PersonId
    const russianName = props.user.russianName
    const notificationToken = props.user.notificationToken
    // const relatedClients = props.user.relatedClients
    const onUserDelete = props.onUserDelete
    const [selected, setSelected] = useState(status)

    const onSelectChange = useCallback((val) => {
        updateUser({ id: id, status: val }).then(() => { })
        setSelected(val)
    }, [])

    return (
        <tr >
            <td className="item__id">
                <span >{id}</span>
            </td>
            <td className="item__name">
                <span >{name}</span>
            </td>
            {selected === 'client' ? <UserSelect id={id} manager={manager} users={props.users}></UserSelect> : <NameSetter id={id} russianName={russianName}></NameSetter>}
            <td className={`user-${status} item__status`} >
                <select defaultValue={status} onChange={(e) => onSelectChange(e.target.value)}>
                    <option>client</option>
                    <option>manager</option>
                    <option>main</option>
                </select>
            </td>
            <td className="item__tokens">
                <Tokens tokens={notificationToken} user={props.user}></Tokens>
            </td>

            <td className="item__delete-button">
                <button onClick={() => {
                    if (window.confirm('Are you shure?')) onUserDelete(id)
                }
                }>x</button>
            </td>
        </tr >
    );
}

export default UserItem;
