import { useCallback, useEffect, useState } from 'react';
import { deleteUser, getOnlineUsers, getUsers } from '../../utils/server';
import UserItem from './UserItem';
import Header from '../Header';

export default function Users() {

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterValue, setFilterValue] = useState('');

    const sortByName = function (a, b) {
        if (!a || !b) return +1;
        if (a > b) return -1;
        return +1;
    }

    useEffect(() => {
        try {
            getUsers().then(users => users.sort((a, b) => sortByName(b.name, a.name))).then(users => users.sort((a, b) => sortByName(a.status, b.status))).then(setUsers);
        } catch (e) {
            // toHome()
            Error("authorization error")
        }
    }, [])
    useEffect(() => {
        try {
            setFilteredUsers(users);
        } catch (e) {
            // toHome()
            Error("Copy to filteredUsers error")
        }
    }, [users])

    const onUserDelete = useCallback((id) => {
        deleteUser(id)
        setUsers(pre => pre.filter(user => user.id !== id))
    }, [])


    const showOnline = async ({ target }) => {

        try {
            const data = await getOnlineUsers();
            const idArr = await data.map(user => user.id);
            const filtered = await users.filter(user => idArr.includes(user.id));

            setFilteredUsers(target.checked ? filtered : users);
        } catch (e) {
            // toHome()

            Error("authorization error")
        }

    }


    useEffect(() => {
        if (filterValue.length) {
            const regulars = filterValue.split(',')
            if (regulars.length) {
                setFilteredUsers(pre => users.filter(user => regulars.some(reg => user.name?.toUpperCase().includes(reg.toUpperCase()))))
            }
        } else {
            // getUsers().then(users => users.forEach(user => !user.id &&  console.log("NO ID", user)))
            // getUsers().then(users => users.forEach(user => console.log(user.name)))
            // setFilteredUsers(users);
            setFilteredUsers(users)
            // console.log('users', JSON.stringify(users));
        }
    }, [filterValue, users])

    function handleContextMenu(event) {
        // event.preventDefault();
        // show({
        //     event,
        //     props: {
        //         key: 'value'
        //     }
        // })
    }
    // console.log('users', users, users.length);
    // console.log('filteredUsers', filteredUsers, filteredUsers.length);
    // users.forEach(user => user.russianName && console.log('russianName', user.russianName))
    // users.forEach(user => user.name === user.id && console.log('SAME_________', user));
    // console.log('users.filter((user) => user.id === user.name)', users.filter((user) => user.id === user.name));
    return (
        <div className='app'>
            <Header />
            <main className="user-container">
                <div className='title'>Користувачі</div>
                <div className='users_filter-block'>
                    <div>
                        <label htmlFor='onlineCheckBox'>Відобразити online</label>
                        <input id='onlineCheckBox' type='checkbox' onChange={(e) => showOnline(e)} />
                    </div>
                    <div>
                        <input placeholder="Знайти по імені" value={filterValue} onChange={e => setFilterValue(e.target.value)} className='user-filter'></input>
                    </div>
                </div>
                <table onContextMenu={handleContextMenu}>
                    <thead>
                        <tr>
                            <th className="item__id">
                                <span >id</span>
                            </th>
                            <th className="item__name">
                                <span >Ім'я</span>
                            </th>
                            <th className="item__russian-name">
                                <span >Ім'я (рос) / id менеджера</span>
                            </th>
                            <th className="item__status">
                                <span >Статус</span>
                            </th>
                            <th className="item__tokens">
                                <span >Токени</span>
                            </th>
                            <th className="item__delete-button">
                                <span >Видалити</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map(((user, i) => {
                            // console.log('user', user);
                            return <UserItem onUserDelete={onUserDelete} users={users} key={i + Math.random()} user={user}></UserItem>
                        })
                        )}
                    </tbody>

                </table>
            </main >
        </div>

    );
}

