import { useState } from "react";
import { login } from "../../utils/server";

export default ({ setIsLogin }) => {
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')

    const loginFun = () => login(name, password).then(({ key }) => setIsLogin(!!key))

    return <div className='login'>
        <div  id="login_title">
            <h1>АДМІНІСТРАТИВНА ПАНЕЛЬ КВАДРО</h1>
        </div>
        <div className="input-block">
            <div >
                <label>Логін:</label>
                <input value={name} onInput={e => setName(e.target.value)} type="text" name="username" />
                <br />
            </div>
            <div >
                <label>Пароль:</label>
                <input value={password} onInput={e => setPassword(e.target.value)} type="password" name="password" />
            </div>
        </div>
        <div >
            <button className="login-confirm" onClick={loginFun}>Підтвердити</button>
        </div>

        <label id="error-message"></label>
    </div>
}