import { useState } from "react"
import { updateUser } from "../../../utils/server"

export default  ({ russianName, id }) => {

    const [name, setName] = useState(russianName || '')

    return <td className="item__russian-name">
        <input value={name} onChange={(e) => setName(pre => e.target.value)} type='text'></input>
        <button  className='' onClick={() => { updateUser({ id, russianName: name }) }}>Ok</button>
        
    </td>
}