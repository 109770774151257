import { useState } from "react"
import { sendPush } from "../../utils/server"
import Header from "../Header"
// import { useHistory } from 'react-router-dom';


export default function Push() {

    const [text, setText] = useState('')


    // const history = useHistory();
    // const toHome = useCallback(() => history.push('/'), [history]);

    return <div className="app">
        <Header />
        <div className='push-sender'>
            <div className="title">Відправлення повідомлення користувачам</div>
            <input value={text} onInput={({ target }) => setText(target.value)} placeholder="Текст повідомлення..."></input>
            <button onClick={() => {
                const res = window.confirm("Точно отправить всем пуш с текстом: " + text)
                if (res) sendPush(text)
                    .then(() => setText(''))
                    .catch((e) => {
                        /*  toHome() */
                    })
            }}>Відправити</button>
        </div >
    </div>
}
