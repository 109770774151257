
export default ({ tokens }) => {
    let tokensArray = tokens
    try {
        tokensArray = JSON.parse(tokens)
    } catch (error) {
    }

    if (Array.isArray(tokensArray)) {
        return tokensArray.map((token, i) => {
            // return<></>
            // console.log('token', typeof token, token);
            // if (typeof token === 'object') console.log(user);
            return <samp className="token" key={i + Math.random()}>{typeof token === 'object' ? (token === null ? '' : token.data ? token.data : "Невідомий") : token}</samp>
        })
    } else return <samp className="token" key={Math.random()} >{tokens}</samp>

}