import { useEffect, useState } from "react";
import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";
import './App.scss';
import Header from './components/Header';
import Login from "./components/Login";
import Notifications from './components/Notifications';
import Push from "./components/Push";
import Users from './components/Users';
import { usersData } from "./utils/data";
import { getUsers } from "./utils/server";


function App() {
  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    if (isLogin)
      getUsers().then(users => {
        usersData.push(...users)
      }).catch(console.log)
  }, [isLogin]);

  return <BrowserRouter>
    <Routes>
      <Route path="/notification" element={isLogin ? <Notifications /> : <Login setIsLogin={setIsLogin} />}>
      </Route>
      <Route path="/users" element={isLogin ? <Users /> : <Login setIsLogin={setIsLogin} />}>
      </Route>
      <Route path="/push" element={isLogin ? <Push /> : <Login setIsLogin={setIsLogin} />}>
      </Route>
      <Route path="/" element={isLogin ? <Header /> : <Login setIsLogin={setIsLogin} />}>
      </Route>
    </Routes>
  </BrowserRouter>
}

export default App;
