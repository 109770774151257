import { useEffect, useState, useCallback, useRef } from "react"
import { getNotification } from '../../utils/server'
import NotificationItem from "./NotificationsItem"
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import Header from "../Header";

const dateTodayForInput = moment().format(`yyyy-MM-DD`)

export default function Notification() {

    const [notifications, setNotifications] = useState([])
    const [dateFrom, setDateFrom] = useState(dateTodayForInput)
    const [dateTo, setDateTo] = useState(dateTodayForInput)

    const navigate = useNavigate();
    // const toHome = useCallback(() => history.push('/'), [history]);

    const dateFromEl = useRef(null)
    const dateToEl = useRef(null)

    useEffect(() => {
        getNotificationData(dateTodayForInput, dateTodayForInput)
    }, [])

    const onFromDataChange = ({ target }) => {
        const date = target.value
        setDateFrom(date)
        console.log(date);
        dateToEl.current.min = date
        getNotificationData(date, dateTo)
    }

    const onToDataChange = ({ target }) => {
        const date = target.value
        setDateTo(date)
        dateFromEl.current.max = date
        getNotificationData(dateFrom, date)
    }

    const getNotificationData = useCallback((dateFrom, dateTo) => getNotification({ dateFrom, dateTo }).then((data) => {
        // console.log(data);
        setNotifications(data)
    }
    ).catch(() => { navigate('/') })
        , [])

    return <div className="app">
        <Header />
        <main className="container">
            <div className="title">Архів повідомлень</div>
            <div className="time-range">
                <div className="time-range__item">
                    <span className="time-range__item-text">From:</span>
                    <input ref={dateFromEl} onChange={onFromDataChange} className="time-range__item-input" type="date" value={dateFrom} name="trip-start" min="2020-01-01" ></input>
                </div>
                <div className="time-range__item">
                    <span className="time-range__item-text">To:</span>
                    <input ref={dateToEl} onChange={onToDataChange} className="time-range__item-input" type="date" value={dateTo} name="trip-start" max={dateTodayForInput} min="2020-01-01" ></input>
                </div>
            </div>

            <table id="notification-table">
                <thead>
                    <tr >
                        <th className="notification-status">
                            Статус
                        </th>
                        <th className="notification-name">
                            Клієнт
                        </th>
                        <th className="notification-text">
                            Замовлення
                        </th>
                        <th className="notification-time">
                            Дата замовлення
                        </th>
                        <th className="notification-id">
                            id
                        </th>
                        <th className="notification-token">
                            Токен
                        </th>
                    </tr>
                </thead>
                <tbody className="notification-list">
                    {notifications.map((item) => <NotificationItem key={item.id} {...item}></NotificationItem>)}
                </tbody>
            </table>


        </main>
    </div>

}
