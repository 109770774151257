import { updateUser } from "../../../utils/server";

function UserSelect(props) {
    const id = props.id;

    return <td className="itme__select">
        <select defaultValue={props.manager} onChange={(e) => updateUser({ id: id, managerId: e.target.value })}>
            <option></option>
            {props.users.filter((user) => user.id === user.name).map((user) => <option key={user.id}>{user.id}</option>)}
        </select>
    </td>

}

export default UserSelect;
